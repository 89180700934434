@use '@angular/material' as mat;

$custom-typography: mat.define-typography-config(
	$font-family: 'Poppins',
);

$primary-theme: mat.define-palette(mat.$light-blue-palette, 800);
$accent-theme: mat.define-palette(mat.$grey-palette, 500);

$warn-theme: mat.define-palette(mat.$red-palette);
$candy-app-theme: mat.define-light-theme($primary-theme, $accent-theme, $warn-theme);

$primary: mat.get-color-from-palette($primary-theme);
$accent: mat.get-color-from-palette($accent-theme);
$warn: mat.get-color-from-palette($warn-theme);
$white: white;
$black: black;
$primary_hover: #F3F6F9;

$no-risk-level-info: #CCC;
$risk-level-low: #018C47;
$risk-level-medium: #F6EA00;
$risk-level-high: red;