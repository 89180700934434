.btn-group.custom-tab {
    display: flex;

    @media (max-width: 767.98px) {
        flex-direction: row !important;

        &.custom-tab__mobile-column {
            flex-direction: column !important;

            .btn {
                width: 100%;
                margin-bottom: .5rem
            }

            .custom-tab__price-text {
                display: none;
            }
        }

    }

    .custom-tab__price-text {
        font-weight: 300;
    }

    .btn {
        flex: 1;
        display: flex;
        flex-direction: column;
        background: #fff;
        border: 0;
        border-bottom: 1px solid var(--primary);
        color: var(--primary) !important;
        margin: 0;
        text-transform: uppercase;
        letter-spacing: 1.3px;
        font-weight: 500;
        white-space: pre-wrap;
        align-items: center;
        justify-content: center;

        &[disabled=disabled] {
            color: var(--gray) !important;
            border-color: var(--gray);
            background-color: var(--light);
        }

        &.btn {
            border-left: 1px solid var(--primary);
        }

        &:first-child {
            border-left: 0px solid; 
        }

        &:last-child {
            border-right: 0px !important;
        }

        &.btn:hover:not(.active) {
            background: var(--secondary);
            border-right: 1px solid var(--primary);
        }

        &.active {
            background: var(--primary);
            border: 0;
            border-bottom: 1px solid var(--light);
            color: var(--light) !important;
            font-weight: 600;
        }
    }
}
